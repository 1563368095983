<template>
  <div>
    <VueUeditorWrap
      v-model="message"
      :config="config"
      editor-id="editor-with-xiumi"
      :editor-dependencies="editorDependencies"
    />
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { domainName } from "@/config";

export default {
  components: {
    VueUeditorWrap,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  created() {
    // if (domainName === "https://admin.101study.cn") {
    //   this.config.UEDITOR_HOME_URL = "/web/UEditor/";
    // }
    // 指定依赖的资源列表，下面的 JS 会依次加载，注意顺序。实际请求资源时会拼接上 UEDITOR_HOME_URL，当然你也可以填完整的 URL
    this.editorDependencies = [
      "ueditor.config.js",
      "ueditor.all.min.js",
      // 添加秀米相关的资源
      // "xiumi/xiumi-ue-dialog-v5.js",
      // "xiumi/xiumi-ue-v5.css",
    ];
    this.message = this.content;
  },
  data() {
    return {
      message: "",
      config: {
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 500, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        serverUrl: domainName + "/frame-web/files/ueditor/exec", // 上传文件接口
        // serverUrl: "https://admin.101study.cn/admin/files/ueditor/exec", // 上传文件接口
        // serverUrl: "http://192.168.20.206:9091/admin/files/ueditor/exec", // 上传文件接口
        UEDITOR_HOME_URL: "/UEditor/", // 访问 UEditor 静态资源的根路径
      },
    };
  },
  watch: {
    content(newVal) {
      this.message = newVal;
    },
    message(newVal, oldVal) {
      this.$emit("editorFn", newVal);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/img {
  max-width: 100%; /*图片自适应宽度*/
}
/deep/.edui-editor-imagescale {
  height: auto !important;
  border: none !important;
}
/deep/.edui-editor-imagescale span {
  background-color: #fff;
}
</style>
